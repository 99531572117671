import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Typography, Box, AppBar } from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import imgLogo from '../src/assets/logoLinear.jpg';
import logo from '../src/assets/logo.jpg';
import Modal from '@material-ui/core/Modal';

import Home from './pages/Home';
import Galeria from './pages/Interior';
import Maps from './pages/Maps';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
});

const theme = createMuiTheme({
  primary: {
    main: '#3493F6',
  },
  secondary: {
    main: '#fff',
  },

  background: '#f5f8fb',
  headerBgColor: '#3493F6',
  headerFontColor: '#fff',
  headerFontSize: '19px',
  botBubbleColor: '#3493F6',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',

});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function App(values) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [open, setOpen] = useState(false);
  const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
  const cellphone = /^[0-9]{2}[0-9]{5}[0-9]{4}$/;
  var nameValue = []

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const handleClose = ({steps, values}) => {
    nameValue = values
  };

  const fecharChat = () =>{
    var nome = nameValue[0];
    var email = nameValue[1];
    var telefone = nameValue[2];
    setOpen(false)
    window.open(`https://teste.dilisgs.com.br/funcphp/registrar_cliente.php?nome=${nome}&email=${email}&telefone=${telefone}`);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);  
  };
  
  useEffect(() => {
  }, []);


  const body = (
    <ThemeProvider theme={theme}>
      <ChatBot
        headerTitle="Atendimento Linear"
        botAvatar={imgLogo}
        placeholder="Digite sua mensagem"
        enableMobileAutoFocus={true}
        hideUserAvatar={true}
        floating={false}
        enableSmoothScroll={true}
        userDelay={0}
        handleEnd={handleClose}
        steps={[
          {
            id: '1',
            message: 'Olá, seja bem-vindo ao chat da Linear.',
            trigger: '2',
          },
          {
            id: '2',
            message: 'Como posso te chamar?',
            trigger: 'name',
          },
          {
            id: 'name',
            user: true,
            placeholder: 'Digite seu nome',
            trigger: '4',
          },
          {
            id: '4',
            message: 'Prazer, {previousValue}. Qual seu email?',
            trigger: 'email',
          },
          {
            id: 'email',
            user: true,
            placeholder: 'Digite seu e-mail',
            trigger: '5',
            validator: (value) => {
              if (pattern.test(value)) {
                return true;
              }
              else {
                return 'E-mail inválido!'
              }
            }
          },
          {
            id: '5',
            message: 'Só mais uma coisa, qual o seu telefone? Digite apenas os números.',
            trigger: 'phone'
          },
          {
            id: 'phone',
            user: true,
            placeholder: '83900000000',
            trigger: '6',
            validator: (value) => {
              if (cellphone.test(value)) {
                return true;
              }
              else {
                return 'Formato de número inválido!'
              }
            }
          },
          {
            id: '6',
            message: 'É isso! Muito obrigado, esperamos que você aproveite toda interação e conheça o prédio. Abraço e até mais!',
            trigger: 7
          },
          {
            id: '7', 
            component: (
              <button className="btnConversa" onClick={fecharChat}>Fechar</button>
            ),
            end: true
          }

        ]}
      />
    </ThemeProvider>
  );

 
  return (
    <div className="App">
      <ThemeProvider theme={{
        primary: {
          main: '#3493F6',
        },
        secondary: {
          main: '#fff',
        },
      }}  >
        <div className="TituloLocal" >
          <img src={logo} className="logoLugar" />

        </div>
        <p style={{ color: '#B0B0B0', fontSize: '9pt' }}>RI - R1144948</p>
        <TabPanel value={value} index={0}>
          <Maps />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Home />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Galeria />
        </TabPanel>

        <AppBar position="fixed" className={classes.appBar}>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
            <Tab label="Maps" icon={<MapRoundedIcon />} {...a11yProps(0)} />
            <Tab label="Home" icon={<HomeRoundedIcon />} {...a11yProps(1)} />
            <Tab label="Galeria" icon={<PhotoLibraryIcon />} {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <div>
          {/*Estrutura do chat */}
          <Modal
            open={open}
          >
            {body}
          </Modal>
        </div>
      </ThemeProvider >


    </div>
  );
}
